.SearchResultsGrid {
  max-height: '90vh';
  max-width: '92vw';
}
.SearchResultsGrid .k-grid-header col:nth-of-type(1),
.SearchResultsGrid .k-grid-table col:nth-of-type(1) {
  width: 30%;
}
.SearchResultsGrid .k-grid-header col:nth-of-type(2),
.SearchResultsGrid .k-grid-table col:nth-of-type(2) {
  width: 15%;
}
.SearchResultsGrid .k-grid-header col:nth-of-type(3),
.SearchResultsGrid .k-grid-table col:nth-of-type(3) {
  width: 20%;
}
.SearchResultsGrid .k-grid-header col:nth-of-type(4),
.SearchResultsGrid .k-grid-table col:nth-of-type(4) {
  width: 10%;
}
.SearchResultsGrid .k-grid-header col:nth-of-type(5),
.SearchReslutsGrid .k-grid-table col:nth-of-type(5) {
  width: 25%;
}

@media screen and (max-width: 800px) {
  .k-grid td {
    padding: 2px 4px !important;
  }
  .k-link {
    padding: 16px 0px !important;
  }
  .SearchResultsGrid tr th:nth-of-type(1) .k-link {
    padding-left: 16px !important;
  }
}

@media screen and (max-width: 550px) {
  .k-grid {
    font-size: 10px !important;
  }
}
