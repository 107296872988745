// Default theme variables here

@import './variables';
@import './typography';
@import './common';
@import './print';

.k-animation-container {
  z-index: 10100;
}
