.answers {
  .k-radio-item {
    padding-top: 10px;
  }

  .k-radio {
    width: 1.5vw;
    height: 1.5vw;
    min-width: 16px;
    min-height: 16px;
  }

  .k-radio:checked::before,
  .k-radio.k-checked::before {
    border-radius: 50%;
    transform: scale(2.5) translate(-50%, -50%);
  }
}
