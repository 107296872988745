// Global variables here
$background-color: #D4D6E4;
$info-background: #3A6E8F;
$react-color: #53d2fa;
$link-color: #71A6FF;
$white: #FFFFFF;

$tiger-team: #FF6358;
$lemon-team: #FFD246;
$organic-team: #55AB1D;
$ocean-team: #28B4C8;