html {
  scroll-behavior: smooth;
}

body {
  --bodyBgColor: rgb(217 217 217);
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bodyBgColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
