#ViewApplication .k-text-disabled {
  opacity: 1;
}

#ViewApplication .k-textbox:disabled,
#ViewApplication .k-textbox[disabled],
#ViewApplication .k-textbox.k-state-disabled,
#ViewApplication .k-state-disabled:not(li.k-state-disabled) {
  border-bottom-style: solid;
  opacity: 1;
}

#ViewApplication li.k-state-disabled,
#ViewApplication .k-radio:disabled + .k-radio-label,
#ViewApplication .k-radio:disabled,
.k-radio.k-disabled,
.k-radio:disabled + .k-radio-label,
.k-radio.k-disabled + .k-radio-label {
  opacity: 1;
  color: #000;
}

.k-pdf-export p {
  font-size: 12px;
}

.k-pdf-export legend {
  font-size: 14px;
  font-weight: normal;
}

.k-pdf-export label,
.k-pdf-export input,
.k-pdf-export textarea {
  font-size: 10px;
}

.k-pdf-export .k-label-optional,
.k-pdf-export .k-form-hint,
.k-pdf-export button[type='submit'],
.k-pdf-export .no-print {
  display: none;
}

.k-pdf-export .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 4px;
}

.k-pdf-export .wp-formLegend-13 {
  margin-top: 8px;
}

.k-pdf-export .k-form .k-form-field, .k-form .k-form-buttons, .k-form .k-daterangepicker .k-textbox-container {
  margin-top: 4px;
}