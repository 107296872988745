.text-decoration-none {
  text-decoration: none;
}

.w-100 {
  width: 100%;
}

.text-underline {
  text-decoration: underline;
}

.link-noStyle {
  color: inherit;
  text-decoration: none !important;
}

.overflow-hidden {
  overflow: hidden;
}

.k-icon-2x {
  font-size: 32px;
}

.k-icon-3x {
  font-size: 48px;
}

.k-icon-4x {
  font-size: 64px;
}

.k-fab {
  z-index: 1000;
}

.k-fab-tertiary {
  border-color: #3a6e8f;
  background-color: #3a6e8f;
}

.k-fab-tertiary:hover {
  border-color: #305d79;
  background-color: #305d79;
}

.k-icon-32 {
  font-size: 32px; /* Sets icon size to 32px */
}

.k-icon-48 {
  font-size: 48px; /* Sets icon size to 48px */
}

.k-icon-64 {
  font-size: 64px; /* Sets icon size to 64px */
}

.fade-in {
  animation: fadeIn 0.4s ease-in 0s 1;
}

.d-flex-col-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.badge-a {
  /* color matched from kendo pager page number highlight but lighter*/
  background: rgb(235, 239, 255);
  border-radius: 5px;
  padding: 0.2rem 0.4rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@import './styles/main.scss';
