li.k-radio-item {
  border-radius: 5px;
  padding-right: 5px;
}

li.k-radio-item label {
  padding-right: 15px;
  padding-left: 15px;
}

.highlight-answer {
  background-color: #d7f0cc;
  border: 1px solid rgba(0, 100, 0, 0.137);
}

.highlight-answer-animate {
  animation: highlightAnswer 1s linear 0s 1;
}

@keyframes highlightAnswer {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
