@media print {
  .no-print {
    display: none;
  }

  .SiteLayout-drawer div.k-drawer {
    display: none;
  }

  #ViewApplication .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  #application-header {
    padding: 0;
    margin: 0;
  }

  #application-header-text {
    font-size: 10px;
  }

  #explanation-text {
    font-size: 16px;
    margin-top: 16px;
  }

  #ViewApplication section {
    padding: 0;
  }
}
