.EditHistoryGrid {
  max-height: 90vh;
}

@media screen and (min-width: 800px) {
  .EditHistoryGrid {
    max-height: 40vh;
  }
  .EditHistoryGrid .k-detail-row ul {
    padding-inline-start: 40px;
  }
}

@media screen and (max-width: 560px) {
  .EditHistoryGrid .k-detail-row ul {
    padding-inline-start: 8px;
  }
  .EditHistoryGrid .k-detail-row .k-detail-cell {
    transform: translateX(-20px);
  }
}
